import React from 'react';
import classnames from 'classnames';
import { shape, object, string } from 'prop-types';
import { Card as AndesCard } from '@andes/card';

import withTracker from '../../../with-tracker';
import withTrackerOnPrint from '../../../with-tracker-on-print';
import { trackEvent } from '../../../../../services/tracking';
import Action from '../../../action';
import IconLocation from '../../../icons/dynamic-access/location';
import IconLowPriceproducts from '../../../icons/dynamic-access/low-price-product';
import IconProtectedBuy from '../../../icons/dynamic-access/protected-buy';
import IconOfficialStore from '../../../icons/dynamic-access/official-store';
import IconMlbLowPriceproducts from '../../../icons/dynamic-access/mlb-low-price-product';
import IconShoesCategory from '../../../icons/dynamic-access/shoes-category';
import IconCellphoneCategory from '../../../icons/dynamic-access/cellphones-category';
import IconCategories from '../../../icons/dynamic-access/categories';
import IconMpeLowPriceproducts from '../../../icons/dynamic-access/mpe-low-price-products';
import IconNewBuyer from '../../../icons/dynamic-access/new-buyers';
import IconFactoryModule from '../../../icons/factory-builder';
import { STATIC_ICON, STATIC_ILLUSTRATION } from '../../../../../../utils/constants';

const IconFactory = IconFactoryModule([
  IconLocation,
  IconLowPriceproducts,
  IconProtectedBuy,
  IconOfficialStore,
  IconMlbLowPriceproducts,
  IconShoesCategory,
  IconCellphoneCategory,
  IconCategories,
  IconMpeLowPriceproducts,
  IconNewBuyer,
]);

const namespace = 'dynamic-access-card';

const CardIconMobile = ({ card, track_event, type, cardHeightClass }) => {
  const { title, picture, subtitle, description, action, card_config, header } = card;
  const fontColor = title?.styles?.font_color;
  const { label, target } = action;
  const handleClick = () => {
    trackEvent(track_event);
  };
  const actionProps = { className: 'action' };
  return (
    <AndesCard
      className={classnames(
        namespace,
        'dynamic-access-card-common',
        {
          'dynamic-access-card-icon': type === STATIC_ICON,
          'dynamic-access-card-ilustrator': type === STATIC_ILLUSTRATION,
          'dynamic-access-card--with-border': card_config?.card_border,
          [`dynamic-access-card-border-color-${card_config?.border_color}`]:
            card_config?.border_color,
        },
        cardHeightClass,
      )}
      style={{
        ...(card_config?.card_radius && {
          '--dynamic-access-card-border-radius': `${card_config?.card_radius}px`,
        }),
        ...(card_config?.card_border && {
          '--dynamic-access-card-border-width': `${card_config?.card_border}px`,
        }),
      }}
      onClick={handleClick}
    >
      {header && (
        <div className="header">
          {header && (
            <h2
              className={classnames('header__title', {
                [`header__title--color-${header.color}`]: header.color,
                [`header__title--bg-color-${header.bg_color}`]:
                  header.bg_color,
              })}
            >
              {header.text}
            </h2>
          )}
        </div>
      )}
      <div className="main">
        {picture && IconFactory(picture.id, { deviceType: 'mobile', alt: title?.text })}
        {title && (
          <p
            className={classnames('title', {
              [`title--color-${title.styles?.color}`]: title.styles?.color,
            })}
            style={{ color: fontColor }}
          >
            {title.text}
          </p>
        )}
        {subtitle && (
          <p className="subtitle">
            {subtitle.text}
          </p>
        )}
        {description && (
          <p className="description">
            {description.text}
          </p>
        )}
      </div>
      <Action
        label={label.text}
        target={target}
        {...actionProps}
      />
    </AndesCard>);
};

CardIconMobile.propTypes = {
  card: shape({
    content: object,
  }),
  track_event: object,
  type: string,
  cardHeightClass: string,
};

export default withTracker(withTrackerOnPrint(CardIconMobile));
